export default async function appauth({ from, to, next, router, auth, store, i18n }) {
    let langprefix = i18n.messages[i18n.locale].basepath.slice(0, -1)
    let langobject = i18n.messages[i18n.locale].allowed_pages
    let langobject2 = i18n.messages[i18n.locale].system_pages

    //console.log(i18n.messages[i18n.locale])
    //console.log(langprefix)

    let alloewd_pages = []

    for (const [key, value] of Object.entries(langobject)) {
        alloewd_pages.push(langprefix + value)
    }

    let alloewd_langbase = []

    // Startseiten der Sprachen Listen
    i18n.availableLocales.forEach((element) => {
        if (element == 'de') {
            alloewd_pages.push('/')
        } else {
            alloewd_pages.push('/' + element + '/')
            alloewd_langbase.push('/' + element + '/')
        }
    })

    if (!alloewd_pages.includes(to.path)) {
        if (!store.state.user || !store.state.user.roles || store.state.user.roles != 'user') {
            // Wenn kein User im Store dann schauen ob Token
            if (auth.token()) {
                // Wenn Token GET User
                await store.dispatch('setUser')
                // Evt Logik einbauen, falls ein User in der falschen Sprache ist, dass er dann auf die andere weitergeleitet wird
                if (!store.state.user || !store.state.user.roles || store.state.user.roles != 'user') {
                    // Wenn kein User dann redirect
                    router.push(langprefix + langobject.start)
                }
            } else {
                // Wenn kein Token Redirect
                router.push(langprefix + langobject.start)
            }
        } else if (store.state.user.roles && store.state.user.roles != 'user') {
            router.push(langprefix + langobject.start)
        }
    } else {
        if (auth.token()) {
            // Wenn eingeloggt dann auf Dashboard weiterleiten
            await store.dispatch('setUser')
            let url = i18n.messages[i18n.locale].basepath + langobject2.dashboard

            if (store.state.user && store.state.user.lang && store.state.user.lang.value) {
                url = i18n.messages[store.state.user.lang.value].basepath + i18n.messages[store.state.user.lang.value].system_pages.dashboard
            }

            router.push(url)
        } else if (alloewd_langbase.includes(to.path)) {
            i18n.locale = to.params.lang // Sprache Ändern und auf Start leiten
        }
    }

    return next()
}
