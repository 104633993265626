import Vue from 'vue'
import Vuex from 'vuex'

import Talk from 'talkjs'
import { $api } from '../plugins/api-requests'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        headerMenu: [],
        footerMenu: [],
        user: {},
        talkSession: null,
        unreadMessages: null,
    },
    mutations: {
        setHeaderMenu(state, posts) {
            if (Array.isArray(posts)) {
                posts.forEach((value, index) => {
                    Vue.set(state.headerMenu, index, value)
                })
                return
            }
            state.headerMenu = posts
        },
        setFooterMenu(state, posts) {
            if (Array.isArray(posts)) {
                posts.forEach((value, index) => {
                    Vue.set(state.footerMenu, index, value)
                })
                return
            }
            state.footerMenu = posts
        },
        setUser(state, user) {
            state.user = user
        },
        setUserProperty(state, { property, value }) {
            const allowedProperties = ['is_onboarded_1', 'is_onboarded_2']
            if (allowedProperties.includes(property)) {
                state.user[property] = value
            } else {
                console.log('Not allowed to manipulate ' + property)
            }
        },
        logoutUser(state, user) {
            state.user = user
        },
        setTalkSession(state, talkSession) {
            state.talkSession = talkSession
        },
        setUnreadMessages(state, unreadMessages) {
            state.unreadMessages = unreadMessages
        },
    },
    actions: {
        async setHeaderMenu({ commit }, lang) {
            let posts = []
            let data = await $api.getHeaderMenu(lang)
            data = data.items

            if (Array.isArray(data)) {
                posts = data
            }

            await commit('setHeaderMenu', posts)
        },
        async setFooterMenu({ commit }, lang) {
            let posts = []
            let data = await $api.getFooterMenu(lang)
            data = data.items

            if (Array.isArray(data)) {
                posts = data
            }

            await commit('setFooterMenu', posts)
        },
        async setUser({ commit }, user) {
            let userdata = false
            try {
                userdata = await Vue.auth.fetch()
            } catch (error) {
                console.error(error)
            }

            if (userdata && userdata.data.success == true) {
                user = userdata.data.user
                Vue.auth.user(user)
                Vue.auth.remember(
                    JSON.stringify({
                        name: user.id,
                    })
                )
            } else {
                const checktoken = await $api.checkToken()
                if (checktoken.data.success != true) {
                    await this.dispatch('logoutUser')
                }
            }
            await commit('setUser', user)

            await this.dispatch('initTalkSession', user)
        },
        async updateUserProperty({ commit }, update) {
            if (update) {
                commit('setUserProperty', update)
            } else {
                console.log('no params')
            }
        },
        async logoutUser({ commit }, user) {
            user = {}
            Vue.auth.unremember()
            Vue.auth.user(user)
            Vue.auth.logout()

            await commit('logoutUser', user)
        },
        async initTalkSession({ commit, state }) {
            if (state.user && !state.talkSession) {
                await Talk.ready
                const me = new Talk.User({
                    id: state.user.wp_id,
                    name: state.user.name,
                    email: state.user.email,
                    photoUrl: state.user.image.link,
                    role: 'default',
                })

                const talkSession = new Talk.Session({
                    appId: 'thfYkOE9',
                    me: me,
                    token: state.user.authToken,
                })

                talkSession.setDesktopNotificationEnabled(true)

                talkSession.unreads.onChange((unreadConversations) => {
                    commit('setUnreadMessages', unreadConversations)
                })

                // Commit the talkSession to the state
                commit('setTalkSession', talkSession)
            }
        },
    },
    getters: {
        headerMenu: (state) => state.headerMenu,
        footerMenu: (state) => state.footerMenu,
        user: (state) => state.user,
        talkSession: (state) => state.talkSession,
        unreadMessages: (state) => state.unreadMessages,
    },
})
