import Vue from 'vue'
import VueRouter from 'vue-router'
import appauth from '../middleware/auth'
import store from '../store'
import i18n from '../plugins/i18n'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/Page.vue'),
        meta: {
            middleware: [appauth],
        },
    },
    {
        path: '/:lang',
        name: 'Home Lang',
        component: () => import('../views/Page.vue'),
        meta: {
            middleware: [appauth],
        },
    },
    {
        path: '/:lang/:slug',
        name: 'Page',
        component: () => import('../views/Page.vue'),
        meta: {
            middleware: [appauth],
        },
    },
    {
        path: '/:lang/:category/:slug',
        name: 'News',
        component: () => import('../views/Page.vue'),
        meta: {
            middleware: [appauth],
        },
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
})

router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware

    const context = {
        to,
        from,
        next,
        router,
        store,
        auth: Vue.auth,
        i18n,
    }
    return middleware[0]({
        ...context,
    })
})

export default router
