import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'de',
    messages: {
        de: {
            version: 'Version',
            menu_id: {
                primary: 23,
                secondary: 3,
            },
            base_id: 4426,
            basepath: '/',
            allowed_pages: {
                start: '/',
                login: '/login/',
                register: '/registrieren/',
                contact: '/hr-kontaktieren/',
            },
            system_pages: {
                // Achtung ohne / vorne
                dashboard: 'dashboard/',
                umfragedanke: 'umfrage-danke/',
                profilbearbeiten: 'profil-bearbeiten/',
            },
        },
        /*en: {
            version: 'Version',
            menu_id: {
                primary: 62,
                secondary: 61,
            },
            base_id: 5942,
            basepath: '/en/',
            allowed_pages: {
                // Achtung ohne / vorne
                start: '/',
                login: '/login/',
                register: '/register/',
                contact: '/hr-kontaktieren/',
            },
            system_pages: {
                dashboard: 'dashboard/',
                umfragedanke: 'umfrage-danke/',
                profilbearbeiten: 'profil-bearbeiten/',
            },
        },
        bg: {
            version: 'Версия',
            menu_id: {
                primary: 127,
                secondary: 120,
            },
            base_id: 10563,
            basepath: '/bg/',
            allowed_pages: {
                // Achtung ohne / vorne
                start: '/',
                login: '/login/',
                register: '/register/',
                contact: '/hr-kontaktieren/',
            },
            system_pages: {
                dashboard: 'dashboard/',
                umfragedanke: 'umfrage-danke/',
            },
        },
        hr: {
            version: 'Verzija',
            menu_id: {
                primary: 128,
                secondary: 121,
            },
            base_id: 10587,
            basepath: '/hr/',
            allowed_pages: {
                // Achtung ohne / vorne
                start: '/',
                login: '/login/',
                register: '/register/',
                contact: '/hr-kontaktieren/',
            },
            system_pages: {
                dashboard: 'dashboard/',
                umfragedanke: 'umfrage-danke/',
                profilbearbeiten: 'profil-bearbeiten/',
            },
        },
        cs: {
            version: 'Verze',
            menu_id: {
                primary: 129,
                secondary: 122,
            },
            base_id: 10624,
            basepath: '/cs/',
            allowed_pages: {
                // Achtung ohne / vorne
                start: '/',
                login: '/login/',
                register: '/register/',
                contact: '/hr-kontaktieren/',
            },
            system_pages: {
                dashboard: 'dashboard/',
                umfragedanke: 'umfrage-danke/',
                profilbearbeiten: 'profil-bearbeiten/',
            },
        },
        hu: {
            version: 'Verzió',
            menu_id: {
                primary: 130,
                secondary: 123,
            },
            base_id: 10661,
            basepath: '/hu/',
            allowed_pages: {
                // Achtung ohne / vorne
                start: '/',
                login: '/login/',
                register: '/register/',
                contact: '/hr-kontaktieren/',
            },
            system_pages: {
                dashboard: 'dashboard/',
                umfragedanke: 'umfrage-danke/',
                profilbearbeiten: 'profil-bearbeiten/',
            },
        },
        pl: {
            version: 'Wersja',
            menu_id: {
                primary: 131,
                secondary: 124,
            },
            base_id: 10697,
            basepath: '/pl/',
            allowed_pages: {
                // Achtung ohne / vorne
                start: '/',
                login: '/login/',
                register: '/register/',
                contact: '/hr-kontaktieren/',
            },
            system_pages: {
                dashboard: 'dashboard/',
                umfragedanke: 'umfrage-danke/',
                profilbearbeiten: 'profil-bearbeiten/',
            },
        },
        ro: {
            version: 'Versiunea',
            menu_id: {
                primary: 132,
                secondary: 125,
            },
            base_id: 10740,
            basepath: '/ro/',
            allowed_pages: {
                // Achtung ohne / vorne
                start: '/',
                login: '/login/',
                register: '/register/',
                contact: '/hr-kontaktieren/',
            },
            system_pages: {
                dashboard: 'dashboard/',
                umfragedanke: 'umfrage-danke/',
                profilbearbeiten: 'profil-bearbeiten/',
            },
        },
        sk: {
            version: 'Verzia',
            menu_id: {
                primary: 133,
                secondary: 126,
            },
            base_id: 10742,
            basepath: '/sk/',
            allowed_pages: {
                // Achtung ohne / vorne
                start: '/',
                login: '/login/',
                register: '/register/',
                contact: '/hr-kontaktieren/',
            },
            system_pages: {
                dashboard: 'dashboard/',
                umfragedanke: 'umfrage-danke/',
                profilbearbeiten: 'profil-bearbeiten/',
            },
        },*/
    },
})

export default i18n
