<template>
    <div id="app">
        <router-view :key="$route.path" />
    </div>
</template>

<script>
export default {
    data() {
        return {
            baseUrl: this.$globalvar.baseurl,
            route: this.$globalvar.baseurl + this.$route.path,
        }
    },

    async created() {
        // save in vuex
        this.$store.dispatch('setHeaderMenu', this.$t('menu_id').primary)
        this.$store.dispatch('setFooterMenu', this.$t('menu_id').secondary)

        if (this.$auth.token()) {
            if (this.user.lang && this.user.lang.value) {
                await this.seti18n()
            }
        } else {
            await this.seti18n()
        }
        document.documentElement.setAttribute('lang', this.$i18n.locale)
    },
    mounted() {
        var self = this

        window.handleOpenURL = function (url) {
            if (url) {
                let link = url.replace('gswb://', '/')
                self.$router.push(link)
            }
        }

        window.addEventListener('statusTap', function () {
            self.$scrollTo('#app')
        })
    },
    computed: {
        user() {
            return this.$store.getters.user
        },
    },
    methods: {
        async seti18n() {
            //console.log(this.user)
            // SET i18n
            var myi18n = await this.$api.getI18nData(this.$i18n.locale)

            if (myi18n.success == true) {
                var locale = this.$i18n.locale
                var messages = this.$i18n.messages[locale]
                messages.wp = myi18n.i18n
                this.$i18n.setLocaleMessage(locale, messages)

                this.$auth.options.logoutData.redirect = this.$t('basepath')
            }
            // SET i18n
        },
    },
    watch: {
        user: {
            handler(val, old) {
                if (val && val.lang && val.lang.value) {
                    this.$i18n.locale = val.lang.value
                    this.seti18n()

                    this.$store.dispatch('setHeaderMenu', this.$t('menu_id').primary)
                    this.$store.dispatch('setFooterMenu', this.$t('menu_id').secondary)
                }
            },
            deep: true,
        },
    },
}
</script>

<style lang="scss">
@import '@/assets/scss/style.scss';

@keyframes moverocket {
    0% {
        background-position: 20% 40%;
    }
    50% {
        background-position: -20% 20%;
    }
    100% {
        background-position: 20% 40%;
    }
}

.mfade-leave-active {
    transition: opacity 0.5 ease;
}

.mfade-enter-active {
    transition: opacity 0.5 ease;
    &:after {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        right: 0;
        background-color: $white;
        opacity: 1;
        z-index: 9999999;
        pointer-events: none;
        transition: all 0.5 linear;
        background-image: url('@/assets/rocket.svg');
        background-size: 120%;
        background-repeat: no-repeat;
        background-position: 20% 40%;

        animation-name: moverocket;
        animation-duration: 1s;
        animation-iteration-count: infinite;
    }
}

.mfade-leave-to {
    opacity: 0;
}

.mfade-enter-from {
    opacity: 0;
    &:after {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        right: 0;
        opacity: 0;
        z-index: 9999999;
        pointer-events: none;

        transform: translateY(00%) translateX(0%);
    }
}
</style>
